<template>
  <div class="create-episode">
    <download-form />
  </div>
</template>

<script>
  import DownloadForm from "../../../components/forms/DownloadForm";
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import articleMixin from "@m@/articleMixin";
  import { mapGetters } from "vuex";
  import Session from "@/common/Session";
  import {SET_ARTICLE} from "@/store/types";
  export default {
    name: "index",
    mixins: [ articleMixin ],
    components: { DownloadForm },
    computed: {
      ...mapGetters(['article']),
      routeLink(){
        return `/articles/${this.aid}/downloads`;
      },
      breadTitle(){
        return this.article.title;
      },
      aid(){
        let { aid } = this.$route.params;
        return aid;
      }
    },
    created() {
      if ( Session.has(`article_${this.aid}`) ){
        this.$store.commit(SET_ARTICLE, Session.get(`article_${this.aid}`));
        this.setBreadCrumb();
      }else {
        this.findArticle(this.aid, false)
          .then(()=>{
            this.setBreadCrumb();
          })
      }
    },
    mounted() {
      this.setBreadCrumb();
    },
    methods: {
      setBreadCrumb(){
        this.$store.dispatch(SET_BREADCRUMB, [
          { title: " ئەسەر باشقۇرۇش", route: "/articles/list" },
          { title: this.breadTitle, route: this.routeLink },
          { title: "يېڭىدىن ھۆججەت قوشۇش" }
        ]);
      },
    }
  }
</script>
